import type { MaskOptions } from 'maska'

/**
 * iso код страны в формате [List of ISO 3166 country codes](https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes)
 **/
export const COUNTRY_CODE = {
  RU: 'RU',
  TJ: 'TJ',
  KG: 'KG',
  KZ: 'KZ',
  BY: 'BY',
  UZ: 'UZ'
} as const

export type CountryCode = InferValues<typeof COUNTRY_CODE>

/**
 * iso код языка в формате [List_of_ISO_639 set-1](https://www.loc.gov/standards/iso639-2/php/code_list.php)
 * @see [Content-Language](https://developer.mozilla.org/ru/docs/Web/HTTP/Headers/Content-Language)
 **/
export const LANGUAGE_CODE = {
  [COUNTRY_CODE.RU]: 'ru',
  [COUNTRY_CODE.TJ]: 'tg',
  [COUNTRY_CODE.BY]: 'be',
  [COUNTRY_CODE.UZ]: 'uz',
  [COUNTRY_CODE.KG]: 'ky',
  [COUNTRY_CODE.KZ]: 'kk'
} as const

export type LanguageCode = InferValues<typeof LANGUAGE_CODE>

export const LANGUAGE_NAME = {
  [COUNTRY_CODE.RU]: 'Русский',
  [COUNTRY_CODE.TJ]: 'Тоҷикӣ',
  [COUNTRY_CODE.BY]: 'Беларускі',
  [COUNTRY_CODE.KZ]: 'Қазақша',
  [COUNTRY_CODE.UZ]: 'O\'zbek',
  [COUNTRY_CODE.KG]: 'Кыргыз тили'
} satisfies Record<CountryCode, string>

export const COUNTRY_NAME_IN_RU = {
  [COUNTRY_CODE.RU]: 'Россия',
  [COUNTRY_CODE.TJ]: 'Таджикистан',
  [COUNTRY_CODE.BY]: 'Беларусь',
  [COUNTRY_CODE.KZ]: 'Казахстан',
  [COUNTRY_CODE.UZ]: 'Узбекистан',
  [COUNTRY_CODE.KG]: 'Кыргызстан'
} satisfies Record<CountryCode, string>

export const CURRENCY_SYMBOL = {
  [COUNTRY_CODE.RU]: '₽',
  [COUNTRY_CODE.TJ]: 'с',
  [COUNTRY_CODE.KZ]: '₸',
  [COUNTRY_CODE.BY]: '₽',
  [COUNTRY_CODE.KG]: 'с',
  [COUNTRY_CODE.UZ]: 'so\'m'
} satisfies Record<CountryCode, string>

export const PHONE_PREFIX = {
  [COUNTRY_CODE.RU]: '+7',
  [COUNTRY_CODE.TJ]: '+992',
  [COUNTRY_CODE.BY]: '+375',
  [COUNTRY_CODE.KZ]: '+7',
  [COUNTRY_CODE.KG]: '+996',
  [COUNTRY_CODE.UZ]: '+998'
} satisfies Record<CountryCode, string>

export const PHONE_MASK_NUMBER_SYMBOL = 'X'

export const PHONE_MASK = {
  [COUNTRY_CODE.RU]: 'XXX XXX-XX-XX',
  [COUNTRY_CODE.TJ]: 'XXX XX XXXX',
  [COUNTRY_CODE.BY]: 'XXX XX-XX-XX',
  [COUNTRY_CODE.KZ]: 'XXXXX X XX XX',
  [COUNTRY_CODE.KG]: 'XXX XXX XXX',
  [COUNTRY_CODE.UZ]: 'XX XXX XX XX'
} satisfies Record<CountryCode, string>

const NINE_MASK_SYMBOL = '&'

const PHONE_FORMAT_RU = `${PHONE_PREFIX[COUNTRY_CODE.RU]} ${PHONE_MASK[COUNTRY_CODE.RU].replace(PHONE_MASK_NUMBER_SYMBOL, NINE_MASK_SYMBOL)}`

export const PHONE_FORMAT_MASK = {
  [COUNTRY_CODE.RU]: PHONE_FORMAT_RU,
  [COUNTRY_CODE.TJ]: `${PHONE_PREFIX[COUNTRY_CODE.TJ]} ${PHONE_MASK[COUNTRY_CODE.TJ]}`,
  [COUNTRY_CODE.BY]: `${PHONE_PREFIX[COUNTRY_CODE.BY]} ${PHONE_MASK[COUNTRY_CODE.BY]}`,
  [COUNTRY_CODE.KZ]: `${PHONE_PREFIX[COUNTRY_CODE.KZ]} ${PHONE_MASK[COUNTRY_CODE.KZ]}`,
  [COUNTRY_CODE.KG]: `${PHONE_PREFIX[COUNTRY_CODE.KG]} ${PHONE_MASK[COUNTRY_CODE.KG]}`,
  [COUNTRY_CODE.UZ]: `${PHONE_PREFIX[COUNTRY_CODE.UZ]} ${PHONE_MASK[COUNTRY_CODE.UZ]}`
}

export const PHONE_PLACEHOLDER = {
  [COUNTRY_CODE.RU]: PHONE_FORMAT_MASK[COUNTRY_CODE.RU].replace(NINE_MASK_SYMBOL, '9'),
  [COUNTRY_CODE.TJ]: PHONE_FORMAT_MASK[COUNTRY_CODE.TJ],
  [COUNTRY_CODE.BY]: PHONE_FORMAT_MASK[COUNTRY_CODE.BY],
  [COUNTRY_CODE.KZ]: PHONE_FORMAT_MASK[COUNTRY_CODE.KZ],
  [COUNTRY_CODE.KG]: PHONE_FORMAT_MASK[COUNTRY_CODE.KG],
  [COUNTRY_CODE.UZ]: PHONE_FORMAT_MASK[COUNTRY_CODE.UZ]
}

export const PHONE_MASK_TOKENS: Required<MaskOptions['tokens']> = {
  [PHONE_MASK_NUMBER_SYMBOL]: { pattern: /\d/ },
  [NINE_MASK_SYMBOL]: { pattern: /9/ }
}

export const ALLOWED_CIS_COUNTRIES_WITHOUT_DOMAIN = [
  COUNTRY_CODE.TJ,
  COUNTRY_CODE.KG,
  COUNTRY_CODE.UZ
] as const
