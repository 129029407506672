import { defineStore } from 'pinia'
import type { CountryCode } from '~/core/i18n'

export const useAppLocale = defineStore('appLocale', () => {
  const isCis = ref(useRuntimeConfig().public.isCis === 'true')

  const isRu = computed(() => !isCis.value)

  const currentCountry = ref<CountryCode | null>(null)

  return {
    isCis,
    isRu,
    currentCountry
  }
})
