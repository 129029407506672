export const useUrls = () => {
  const {
    mfUrl,
    mfUrlCis,
    mfUrlKz,
    mfUrlBy
  } = useRuntimeConfig().public
  const { hostname } = useRequestURL()

  const toHostName = (url: string) => new URL(url).hostname

  return {
    mfUrl,
    mfUrlCis,
    mfUrlKz,
    mfUrlBy,
    mlUrl: 'https://medlock.ru',
    medRocketUrl: 'https://medrocket.me/',
    rabotaMedRocketUrl: 'https://rabota.medrocket.ru/',
    helpMedRocketUrl: 'https://help.medrocket.ru/',
    helpMfUrl: 'https://help.medflex.ru/',
    pdUrl: 'https://prodoctorov.ru/',
    mtUrl: 'https://medtochka.ru/',
    ptUrl: 'https://protabletky.ru/',
    pbUrl: 'https://probolezny.ru/',
    ydocBy: 'https://ydoc.by/',
    ydocTj: 'https://ydoc.tj/',
    ydocKz: 'https://ydoc.kz/',
    ydocKg: 'https://ydoc.kg/',
    ydocUz: 'https://ydoc.uz/',
    get mfSecurityPolicy() {
      return '/static/dms/pdf/personal_data_processing_policy.pdf'
    },
    get mfUserAgreement() {
      return '/static/dms/pdf/user_agreement.pdf'
    },
    get mfLicenseAgreement() {
      return '/static/dms/pdf/medflex_contract.pdf'
    },
    get mfUserManual() {
      return '/static/docs/user_manual.pdf'
    },
    get mfCertificate() {
      return '/static/dms/pdf/certificate_of_conformity.pdf'
    },
    get governmentDecree() {
      return '/cabinet/docs/government-decree-n852-from-01-06-2021.pdf'
    },
    get apiMf() {
      switch (hostname) {
        case toHostName(this.mfUrl):
          return 'https://api.medflex.ru'
        case toHostName(this.mfUrlKz):
          return 'https://api.medflex.kz'
        case toHostName(this.mfUrlBy):
          return 'https://api.medflex.by'
        default:
          return 'https://api.medflex.me'
      }
    }
  }
}
