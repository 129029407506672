import { ASYNC_DATA_KEYS } from '~/constants'
import { homeApi } from '~/api'
import { partnerSectionApi } from '~/components/PartnerSection/api'
import { getMisStatistics } from '~/api/misStatistics'
import { getPartnersFeaturesTableInfo } from '~/features/PartnersTable/api'

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.client) {
    return
  }

  const locale = useAppLocale()

  const country = locale.currentCountry ?? undefined

  if ($featureFlag('feature_split_landing_data_by_country') && !country) {
    return
  }

  const canFetchAdditionalData = !$featureFlag('feature_split_landing_data_by_country') || locale.isRu

  const [{ error }] = await Promise.all([
    useAsyncData(ASYNC_DATA_KEYS.statics, () => homeApi.getStatistics(country)),
    useAsyncData(ASYNC_DATA_KEYS.misList, () => getMisStatistics(country), {
      default() {
        return []
      }
    }),
    canFetchAdditionalData
      ? useAsyncData(ASYNC_DATA_KEYS.logos, () => partnerSectionApi.getLogos())
      : Promise.resolve(),
    $featureFlag('feature_add_table_partner_integration') && canFetchAdditionalData
      ? useAsyncData(ASYNC_DATA_KEYS.partnersFeaturesTableInfo, () => getPartnersFeaturesTableInfo(), {
        default() {
          return []
        }
      })
      : Promise.resolve()
  ])

  if (error.value) {
    return abortNavigation({
      ...error.value,
      statusCode: 500
    })
  }
})
