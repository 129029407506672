import { getCountryFromUrl } from '~/core/i18n'
import type { RouteMiddleware } from '#app'

export const validateCountryMiddleware: RouteMiddleware = async (to) => {
  const abortWithNotFound = async (reason: string) => abortNavigation({
    message: `abortWithNotFound: ${reason}`,
    statusCode: 404
  })

  if (!$featureFlag('feature_split_landing_data_by_country')) {
    return to.params.country ? abortWithNotFound('Флаг выключен, получен параметр страны') : true
  }

  const { hostname } = useRequestURL()
  const country = getCountryFromUrl({
    hostname,
    routeCountryParam: to.params.country
  })

  const locale = useAppLocale()
  locale.currentCountry = country

  if (!country) {
    return locale.isCis && to.name === 'country'
      ? true
      : abortWithNotFound(`Не получена страна на origin(${useRequestURL().origin})`)
  }

  const { mfUrl, mfUrlCis } = useUrls()
  const mfHost = new URL(mfUrl).hostname
  const mfHostCis = new URL(mfUrlCis).hostname

  const hasCountryParam = Boolean(to.params.country)

  if (locale.isCis && hostname === mfHost) {
    return abortWithNotFound('locale isCis, origin is Ru')
  }

  if (locale.isRu && hostname !== mfHost) {
    return abortWithNotFound('locale isRu, origin is CIS')
  }

  if (hostname === mfHostCis) {
    return hasCountryParam || abortWithNotFound('Параметр country отсутствует на CIS домене')
  }

  return hasCountryParam ? abortWithNotFound(`Лишний country параметр для домена ${hostname}`) : true
}
