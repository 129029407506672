
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as clinicser63zNkRmRMeta } from "/builds/mis/medflex_landing/pages/[[country]]/clinics.vue?macro=true";
import { default as indexizkDyb5i1vMeta } from "/builds/mis/medflex_landing/pages/[[country]]/index.vue?macro=true";
import { default as insurance7tlb2g8RzFMeta } from "/builds/mis/medflex_landing/pages/[[country]]/insurance.vue?macro=true";
import { default as _91id_93Tips7z1stZMeta } from "/builds/mis/medflex_landing/pages/[[country]]/integration/[id].vue?macro=true";
import { default as miseKYo9BcCMAMeta } from "/builds/mis/medflex_landing/pages/[[country]]/mis.vue?macro=true";
import { default as servicesFyzVBs9Hd9Meta } from "/builds/mis/medflex_landing/pages/[[country]]/services.vue?macro=true";
export default [
  {
    name: "country-clinics",
    path: "/:country?/clinics",
    component: () => import("/builds/mis/medflex_landing/pages/[[country]]/clinics.vue")
  },
  {
    name: "country",
    path: "/:country?",
    component: () => import("/builds/mis/medflex_landing/pages/[[country]]/index.vue")
  },
  {
    name: "country-insurance",
    path: "/:country?/insurance",
    meta: insurance7tlb2g8RzFMeta || {},
    component: () => import("/builds/mis/medflex_landing/pages/[[country]]/insurance.vue")
  },
  {
    name: "country-integration-id",
    path: "/:country?/integration/:id()",
    component: () => import("/builds/mis/medflex_landing/pages/[[country]]/integration/[id].vue")
  },
  {
    name: "country-mis",
    path: "/:country?/mis",
    component: () => import("/builds/mis/medflex_landing/pages/[[country]]/mis.vue")
  },
  {
    name: "country-services",
    path: "/:country?/services",
    component: () => import("/builds/mis/medflex_landing/pages/[[country]]/services.vue")
  }
]