import validate from "/builds/mis/medflex_landing/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45redirect_45to_45trailing_45slash_45global from "/builds/mis/medflex_landing/middleware/0.redirectToTrailingSlash.global.ts";
import _1_45flags_45global from "/builds/mis/medflex_landing/middleware/1.flags.global.ts";
import _2_45validate_45country_45global from "/builds/mis/medflex_landing/middleware/2.validateCountry.global.ts";
import _3_45global_45data_45global from "/builds/mis/medflex_landing/middleware/3.globalData.global.ts";
import manifest_45route_45rule from "/builds/mis/medflex_landing/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45redirect_45to_45trailing_45slash_45global,
  _1_45flags_45global,
  _2_45validate_45country_45global,
  _3_45global_45data_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}