import { isString } from 'lodash-es'
import { type CountryCode, ALLOWED_CIS_COUNTRIES_WITHOUT_DOMAIN, COUNTRY_CODE } from './constants'

export type GetCountryFromUrlPayload = {
  routeCountryParam: unknown
  hostname: string
}

export const getCountryFromUrl = ({ routeCountryParam, hostname }: GetCountryFromUrlPayload): CountryCode | null => {
  const { mfUrlKz, mfUrlBy, mfUrlCis, mfUrl } = useUrls()
  const toHostName = (url: string) => new URL(url).hostname

  switch (hostname) {
    case toHostName(mfUrl):
      return COUNTRY_CODE.RU
    case toHostName(mfUrlKz):
      return COUNTRY_CODE.KZ
    case toHostName(mfUrlBy):
      return COUNTRY_CODE.BY
    case toHostName(mfUrlCis):
      return isString(routeCountryParam)
        ? ALLOWED_CIS_COUNTRIES_WITHOUT_DOMAIN.find(iso => iso === routeCountryParam.toUpperCase()) ?? null
        : null
    default:
      return null
  }
}
