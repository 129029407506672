import type { RouteLocationAsRelativeGeneric } from 'vue-router'

export const linkTo = (to: RouteLocationAsRelativeGeneric) => {
  const route = useRoute()

  return {
    ...to,
    params: {
      ...to.params,
      country: route.params.country
    }
  }
}
